// const API_BASE_URL = 'http://51.21.72.99:8000';
// const API_BASE_URL = 'http://localhost:8000'
const API_BASE_URL = 'https://comply.setu.co/api';

const config = {
    API_BASE_URL,
    UPLOAD_FILE_URL: `${API_BASE_URL}/uploadfile/`,
    PROCESS_FILE_URL: `${API_BASE_URL}/processfile/`,
    EMAIL_FILE_URL: `${API_BASE_URL}/emailfile/?email_to=`,
    CHATBOT_URL: `${API_BASE_URL}/qna`,
    
    // google auth related stuff
    current_user: `${API_BASE_URL}/current_user`,
    logout: `${API_BASE_URL}/logout`,
    googlelogin: `${API_BASE_URL}/auth/google`,
    ENABLE_GOOGLE_AUTH: true,
};

export default config;
