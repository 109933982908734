import React, { useState, useEffect, useRef } from 'react';
import './Chatbot.css'; // Include your updated styles here
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import config from '../Config/config';

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [chatLog, setChatLog] = useState([]);
    const [isTyping, setIsTyping] = useState(false); // State to manage typing animation
    const [showPlaceholder, setShowPlaceholder] = useState(true); // State to manage placeholder text
    const chatBodyRef = useRef(null);

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [chatLog, isTyping]);

    const toggleChat = () => {
        setIsOpen(!isOpen);

        // If chat is opened and no messages exist, show typing animation and then the initial bot message
        if (!isOpen && chatLog.length === 0) {
            setIsTyping(true);
            setTimeout(() => {
                setChatLog([{ sender: 'bot', message: "Hey, we don't believe in greetings. Shoot your question!" }]);
                setIsTyping(false);
            }, 1000); // Adjust the delay as needed
        }
    };

    const handleSend = async () => {
        if (message.trim()) {
            if (showPlaceholder) {
                setShowPlaceholder(false); // Hide placeholder text when user starts interacting
            }

            setChatLog(prev => [...prev, { sender: 'user', message }]);
            setMessage('');

            setIsTyping(true); // Show typing animation

            try {
                const response = await axios.get(config.CHATBOT_URL, {
                    params: {
                        question: message.trim(),
                    },
                });

                if (response.status === 200) {
                    const data = response.data;
                    console.log('API response:', data);

                    setIsTyping(false); // Hide typing animation

                    setChatLog(prev => [...prev, { sender: 'bot', message: data.answer }]);
                    if (data.alt_answer) {
                        setChatLog(prev => [...prev, { sender: 'bot', message: data.alt_answer }]);
                    }
                    else {
                        setIsTyping(false);
                    }
                } else {
                    console.error(`HTTP error! Status: ${response.status}`);
                    setIsTyping(false); // Hide typing animation even if there's an error
                }

            } catch (error) {
                console.error('Error:', error);
                setIsTyping(false); // Hide typing animation on error
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSend();
        }
    };

    return (
        <div className="chatbot">
            {!isOpen && (
                <div className="chat-button" onClick={toggleChat}>
                    Ask ComplyBot
                </div>
            )}

            {isOpen && (
                <div className="chat-window">
                    <div className="chat-header">
                        <h3>ComplyBot</h3>
                        <button onClick={toggleChat} className="close-chat">X</button>
                    </div>
                    <div className="chat-body" ref={chatBodyRef}>
                        {showPlaceholder && (
                            <div className="placeholder-text">
                                Ask anything to ComplyBot
                            </div>
                        )}
                        {chatLog.map((chat, index) => (
                            <div key={index} className={`message ${chat.sender}`}>
                                {chat.message}
                            </div>
                        ))}
                        {isTyping && (
                            <div className="typing-indicator">
                                <span></span><span></span><span></span>
                            </div>
                        )}
                    </div>
                    <div className="chat-footer">
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={handleKeyDown} // Added this line to handle Enter key
                            placeholder="Type your message..."
                        />
                        <button onClick={handleSend} className="send-button">
                            <i className="fas fa-paper-plane"></i> {/* Font Awesome send icon */}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chatbot;
