export const handleApiError = (error) => {
    if (error.response && error.response.data && error.response.data.detail) {
        const detailMessage = error.response.data.detail;
        console.log('API Error Detail:', detailMessage);
        // Check for "Sheet(s) ['Sheet'] not found in the uploaded file." error
        const sheetNotFoundMatch = detailMessage.match(/Sheet\(s\) \['([^']+)'\] not found in the uploaded file/);
        if (sheetNotFoundMatch) {
            return `${sheetNotFoundMatch[1]} not found`;
        }

        // Check for "Column 'ColumnName' not found in sheet" error
        const columnNotFoundMatch = detailMessage.match(/Column\s'([^']+)' not found in sheet/);
        if (columnNotFoundMatch) {
            return `${columnNotFoundMatch[1]} column not found`;
        }

        
        // Return the detail message as a fallback
        return detailMessage;
    } else {
        return 'An unknown error occurred. Please try again later.';
    }
};
