import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './FileUpload.css';
import { handleApiError } from '../Errors/ErrorMessages';
import Chatbot from '../Chatbot/Chatbot';
import config from '../Config/config';
import NavBar from '../NavBar/NavBar';
import About from '../About/About';
import loadingGif from '../assets/loading.gif';

function FileUpload() {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [dragging1, setDragging1] = useState(false);
  const [dragging2, setDragging2] = useState(false);
  const [sheetName1, setSheetName1] = useState('');
  const [sheetName2, setSheetName2] = useState('');
  const [questionsColumn1, setQuestionsColumn1] = useState('');
  const [answersColumn1, setAnswersColumn1] = useState('');
  const [questionsColumn2, setQuestionsColumn2] = useState('');
  const [answersColumn2, setAnswersColumn2] = useState('');
  const [uploading1, setUploading1] = useState(false);
  const [uploadSuccess1, setUploadSuccess1] = useState(false);
  const [uploading2, setUploading2] = useState(false);
  const [uploadSuccess2, setUploadSuccess2] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSentMessage, setEmailSentMessage] = useState('');
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const navigate = useNavigate();
  const [uploadError1, setUploadError1] = useState('');
  const [uploadError2, setUploadError2] = useState('');
  const [visibleSection, setVisibleSection] = useState('getAnswers');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  // eslint-disable-next-line
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const sectionRefs = {
    trainModel: useRef(null),
    getAnswers: useRef(null),
    about: useRef(null),
  };
  const [userName, setUserName] = useState('');

  const showSection = (section) => {
    setVisibleSection(section);
    if (sectionRefs[section].current) {
      sectionRefs[section].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (config.ENABLE_GOOGLE_AUTH) {
      // Fetch authentication status from the backend
      fetch(config.current_user, {
        credentials: 'include',
      })
        .then(response => response.json())
        .then(user => {
          if (user) {
            setIsAuthenticated(true);
            setUserName(user.given_name);
          } else {
            setIsAuthenticated(false);
            navigate('/');  // Redirect to login if not authenticated
          }
        })
        .catch(error => {
          console.error('Error fetching authentication status:', error);
          setIsAuthenticated(false);
          navigate('/');  // Redirect to login on error
        });
    } else {
      // If Google auth is disabled, set user as authenticated directly
      setIsAuthenticated(true);
    }
  }, [navigate]);

  const handleFileChange = (e, fileSetter, uploadSuccessSetter) => {
    const file = e.target.files[0];

    e.target.value = null;

    fileSetter(file);
    uploadSuccessSetter(false);
  };

  const handleSheetNameChange = (e, setSheetName, setUploadSuccess) => {
    setSheetName(e.target.value);
    setUploadSuccess(false);
  };


  const handleUpload = async (file, sheetName, questionsColumn, answersColumn, setUploading, setUploadSuccess) => {
    if (!file || !sheetName || !questionsColumn || !answersColumn) return;
    setUploading(true);
    setUploadSuccess(false);
    setUploadError1(''); // Reset the error message
    const formData = new FormData();
    formData.append('file', file);
    formData.append('sheet_names', sheetName);
    formData.append('question_column', questionsColumn);
    formData.append('answers_column', answersColumn);
    try {
      await axios.post(config.UPLOAD_FILE_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUploadSuccess(true);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const errorMessage = handleApiError(error);
        setUploadError1(errorMessage);
      } else {
        console.error('Error uploading file:', error);
        setUploadError1('Error occurred: Please try again.'); // Set generic error message
      }
    } finally {
      setUploading(false);
    }
  };

  const processFile = async (file, sheetName, questionsColumn, answersColumn, setUploading, setUploadSuccess) => {
    if (!file || !sheetName || !questionsColumn || !answersColumn) return;

    setUploading(true);
    setUploadSuccess(false);
    setUploadError2(''); // Reset the error message for section 2
    const formData = new FormData();
    formData.append('file', file);
    formData.append('sheet_names', sheetName);
    formData.append('questions_column', questionsColumn);
    formData.append('comments_column', answersColumn);

    try {
      const response = await axios.post(config.PROCESS_FILE_URL, formData, {
        responseType: 'blob', // We expect a blob, but errors might be in JSON
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const downloadUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'processed_file.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setUploadSuccess(true);
      } else {
        console.error('File processing failed with status:', response.status);
        setUploadError2('File processing failed. Please try again.'); // Set a generic error message if processing fails
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Manually parse the error blob as text
        const reader = new FileReader();
        reader.onload = function () {
          try {
            const errorJson = JSON.parse(reader.result);
            const errorMessage = handleApiError({ response: { data: errorJson } });
            setUploadError2(errorMessage);
          } catch (e) {
            setUploadError2('File processing error: Unable to parse error response.');
          }
        };
        reader.onerror = function () {
          setUploadError2('Error reading error response.');
        };
        reader.readAsText(error.response.data); // Read the blob as text
      } else {
        console.error('Error processing file:', error);
        setUploadError2('Error occurred: Please try again.');
      }
    } finally {
      setUploading(false);
    }
  };



  const handleDrop = (e, fileSetter, draggingSetter, setUploading, setUploadSuccess) => {
    e.preventDefault();
    draggingSetter(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      fileSetter(droppedFile);
      handleUpload(
        droppedFile,
        setFile1 === fileSetter ? sheetName1 : sheetName2,
        setFile1 === fileSetter ? questionsColumn1 : questionsColumn2,
        setFile1 === fileSetter ? answersColumn1 : answersColumn2,
        setUploading,
        setUploadSuccess
      );
    }
  };

  const handleDragOver = (e, draggingSetter) => {
    e.preventDefault();
    draggingSetter(true);
  };

  const handleDragLeave = (draggingSetter) => {
    draggingSetter(false);
  };

  const handleClickUploadText = (fileInputRef) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const handleEmailMeClick = () => {
    setShowEmailInput(true);
  };

  const handleSendClick = async () => {
    if (!email) return;

    setIsSendingEmail(true);
    setShowEmailInput(false);

    try {
      const response = await sendEmailNotification(email);

      setTimeout(() => {
        setIsSendingEmail(false);  // Stop the animation

        if (response && response.status === 200) {
          setIsEmailSent(true);
          setEmailSentMessage('Email sent successfully');
        } else {
          setIsEmailSent(true);
          setEmailSentMessage('Failed to send email. Please try again later.');
        }
      }, 2000);

    } catch (error) {
      setTimeout(() => {
        setIsSendingEmail(false);
        setIsEmailSent(true);
        setEmailSentMessage('Error sending email. Please try again later.');
      }, 2000);  // Delay for 2 seconds to allow the animation to run fully

      console.error('Error sending email:', error);

    } finally {
    }
  };



  const sendEmailNotification = async (email) => {
    try {
      const response = await axios.post(`${config.EMAIL_FILE_URL}${encodeURIComponent(email)}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className={`upload-container ${isCollapsed ? 'collapsed' : ''}`}>
      {/* Pass collapsed state to NavBar and handle the collapse behavior */}
      <NavBar visibleSection={visibleSection} showSection={showSection} setIsCollapsed={setIsCollapsed} />
      <span className="user-name">
        <span className="hey-text">hey,</span> {userName}!
      </span>
      <Chatbot />
      <div className="upload-sections">
        {visibleSection === 'trainModel' && (
          <div ref={sectionRefs.trainModel} className="upload-section">
            <h2>Train the model</h2>
            <div
              className={`drop-zone ${dragging1 ? 'dragging' : ''}`}
              onDrop={(e) => handleDrop(e, setFile1, setDragging1, setUploading1, setUploadSuccess1)}
              onDragOver={(e) => handleDragOver(e, setDragging1)}
              onDragLeave={() => handleDragLeave(setDragging1)}
            >
              {file1 ? (
                <>
                  {/* File display with icon and file name */}
                  <div className="file-details">
                    <i className="fas fa-file-alt file-icon"></i> {/* Font Awesome file icon */}
                    <span className="file-name">{file1.name}</span>
                    <span className="file-size">({(file1.size / 1024).toFixed(2)} KB)</span>
                  </div>

                  {/* Option to upload another file */}
                  <p className="click-upload-text" onClick={() => handleClickUploadText(fileInputRef1)}>
                    Click here to upload again
                  </p>
                </>
              ) : (
                <>
                  {/* Initial message for uploading */}
                  <p>Drag & drop a file here or</p>
                  <p className="click-upload-text" onClick={() => handleClickUploadText(fileInputRef1)}>
                    Click here to upload
                  </p>
                </>
              )}
              <input
                type="file"
                onChange={(e) => handleFileChange(e, setFile1, setUploadSuccess1)}
                className="file-input"
                ref={fileInputRef1}
                style={{ display: 'none' }}
              />

              {/* Show inputs for Excel files */}
              {file1 && (file1.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file1.type === 'application/vnd.ms-excel') && (
                <>
                  <div className="input-pair">
                    <div className="input-group">
                      <label htmlFor="sheetName1">Enter the sheet name:</label>
                      <input
                        id="sheetName1"
                        type="text"
                        value={sheetName1}
                        placeholder="Sheet name"
                        onChange={(e) => handleSheetNameChange(e, setSheetName1, setUploadSuccess1)}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="questionsColumn1">Enter questions column name:</label>
                      <input
                        id="questionsColumn1"
                        type="text"
                        value={questionsColumn1}
                        placeholder="Column Name"
                        onChange={(e) => setQuestionsColumn1(e.target.value)}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="answersColumn1">Enter comments column name:</label>
                      <input
                        id="answersColumn1"
                        type="text"
                        value={answersColumn1}
                        placeholder="Column Name"
                        onChange={(e) => setAnswersColumn1(e.target.value)}
                      />
                    </div>
                  </div>
                </>
              )}

              {/* Show the upload button after a file is uploaded */}
              {file1 && (
                <button
                  onClick={() => {
                    if (
                      file1.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
                      file1.type !== 'application/vnd.ms-excel'
                    ) {
                      // For non-Excel files, the button is enabled immediately but no inputs are required
                      handleUpload(file1, 'dummy', 'dummy', 'dummy', setUploading1, setUploadSuccess1);
                    } else {
                      // For Excel files, ensure all inputs are filled before making the API call
                      if (sheetName1 && questionsColumn1 && answersColumn1) {
                        handleUpload(file1, sheetName1, questionsColumn1, answersColumn1, setUploading1, setUploadSuccess1);
                      }
                    }
                  }}
                  className={`upload-button ${uploading1 ? 'uploading' : ''} ${uploadSuccess1 ? 'upload-done' : ''}`}
                  disabled={
                    uploading1 ||
                    (file1 &&
                      (file1.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        file1.type === 'application/vnd.ms-excel') &&
                      (!sheetName1 || !questionsColumn1 || !answersColumn1)
                    )
                  }
                >
                  {uploading1 ? (
                    <div className="spinner"></div>
                  ) : uploadSuccess1 ? (
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                      <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                      <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>
                  ) : (
                    'Upload'
                  )}
                </button>
              )}

              {uploadError1 && <div className="error-message">{uploadError1}</div>}
            </div>
          </div>
        )}


        {visibleSection === 'getAnswers' && (
          <div className="upload-section">
            <h2>Get your answers</h2>
            <div
              className={`drop-zone ${dragging2 ? 'dragging' : ''}`}
              onDrop={(e) => handleDrop(e, setFile2, setDragging2, setUploading2, setUploadSuccess2)}
              onDragOver={(e) => handleDragOver(e, setDragging2)}
              onDragLeave={() => handleDragLeave(setDragging2)}
            >
              {file2 ? (
                <>
                  {/* File display with icon and file name */}
                  <div className="file-details">
                    <i className="fas fa-file-alt file-icon"></i> {/* Font Awesome file icon */}
                    <span className="file-name">{file2.name}</span>
                    <span className="file-size">({(file2.size / 1024).toFixed(2)} KB)</span>
                  </div>

                  {/* Option to upload another file */}
                  <p className="click-upload-text" onClick={() => handleClickUploadText(fileInputRef2)}>
                    Click here to upload again
                  </p>
                </>
              ) : (
                <>
                  {/* Initial message for uploading */}
                  <p>Drag & drop a file here or</p>
                  <p className="click-upload-text" onClick={() => handleClickUploadText(fileInputRef2)}>
                    Click here to upload
                  </p>
                </>
              )}
              <input
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFile2(file);
                  setUploadSuccess2(false);
                  setUploadError2(''); // Reset error message

                  // Check if the file is not an Excel file and show error message
                  if (
                    file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
                    file.type !== 'application/vnd.ms-excel'
                  ) {
                    setUploadError2('Please upload an Excel file. Current support is only for Excel files.');
                  }
                }}
                className="file-input"
                ref={fileInputRef2}
                style={{ display: 'none' }}
              />

              {/* Show error message if the file is not an Excel file */}
              {uploadError2 && <div className="error-message">{uploadError2}</div>}

              {/* Show input fields and button only for valid Excel file */}
              {file2 && !uploadError2 && (
                <>
                  <div className="input-pair">
                    <div className="input-group">
                      <label htmlFor="sheetName2">Enter sheet name</label>
                      <input
                        id="sheetName2"
                        type="text"
                        value={sheetName2}
                        placeholder="Sheet name"
                        onChange={(e) => handleSheetNameChange(e, setSheetName2, setUploadSuccess2)}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="questionsColumn2">Enter questions column name</label>
                      <input
                        id="questionsColumn2"
                        type="text"
                        value={questionsColumn2}
                        placeholder="Column Name"
                        onChange={(e) => setQuestionsColumn2(e.target.value)}
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="answersColumn2">Enter answers column name</label>
                      <input
                        id="answersColumn2"
                        type="text"
                        value={answersColumn2}
                        placeholder="Column Name"
                        onChange={(e) => setAnswersColumn2(e.target.value)}
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => processFile(file2, sheetName2, questionsColumn2, answersColumn2, setUploading2, setUploadSuccess2)}
                    className={`upload-button ${uploading2 ? 'uploading' : ''} ${uploadSuccess2 ? 'upload-done' : ''}`}
                    disabled={uploadSuccess2 || !file2 || !sheetName2 || !questionsColumn2 || !answersColumn2 || uploading2}
                  >
                    {uploading2 ? (
                      <div className="spinner"></div>
                    ) : uploadSuccess2 ? (
                      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                      </svg>
                    ) : (
                      'Process and Download'
                    )}
                  </button>
                </>
              )}

              {/* Show email options and send button */}
              {file2 && uploadSuccess2 && !showEmailInput && (
                <>
                  <p
                    className="click-upload-text email-me-active"
                    onClick={handleEmailMeClick}
                  >
                    Email Me
                  </p>
                  {/* Animation and message container */}
                  <div className="animation-container">
                    {isSendingEmail && <img src={loadingGif} alt="Loading..." className="loading-gif" />}
                    {!isSendingEmail && isEmailSent && (
                      <p className={`email-sent-message ${emailSentMessage.includes('Error') ? 'error' : 'success'}`}>
                        {emailSentMessage}
                      </p>
                    )}
                  </div>
                </>
              )}

              {file2 && uploadSuccess2 && showEmailInput && (
                <div className="email-input-container">
                  <input
                    type="email"
                    value={email}
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="email-input"
                  />
                  <button onClick={handleSendClick} className="send-email-button">
                    Send
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        {visibleSection === 'about' && (
          <div ref={sectionRefs.about} className="upload-section">
            <About />
          </div>
        )}
      </div>
    </div>
  );
}

export default FileUpload;