import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../Config/config';
import logo from '../assets/setu-logo.svg'; // Full logo for expanded mode
import icon from '../assets/setu-icon.png'; // Collapsed logo
import './NavBar.css';

function NavBar({ visibleSection, showSection, setIsCollapsed }) {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const handleLogout = () => {
    axios.get(config.logout, { withCredentials: true })
      .then(() => {
        navigate('/');
      })
      .catch((err) => {
        console.error('Logout error:', err);
      });
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setIsCollapsed(!collapsed); // Pass collapsed state to parent
  };

  return (
    <nav className={`side-nav ${collapsed ? 'collapsed' : ''}`}>
      <div className="collapse-button" onClick={toggleSidebar}>
        <i className={`collapse-icon ${collapsed ? 'fa fa-chevron-right' : 'fa fa-chevron-left'}`}></i>
      </div>
      <div className="logo-container">
        <img 
          src={collapsed ? icon : logo} 
          alt="Logo" 
          className={`logo ${collapsed ? 'collapsed-logo' : ''}`} 
        />
      </div>
      <div className="nav-links">
        <div
          onClick={() => showSection('getAnswers')}
          className={`nav-item ${visibleSection === 'getAnswers' ? 'active' : ''}`}
        >
          <i className="fa fa-question-circle nav-icon"></i>
          {!collapsed && <span className="nav-text">Get Your Answers</span>}
        </div>
        <div className="divider-line"></div>
        <div
          onClick={() => showSection('trainModel')}
          className={`nav-item ${visibleSection === 'trainModel' ? 'active' : ''}`}
        >
          <i className="fa fa-cogs nav-icon"></i>
          {!collapsed && <span className="nav-text">Train the Model</span>}
        </div>
        <div className="divider-line"></div>
        <div
          onClick={() => showSection('about')}
          className={`nav-item ${visibleSection === 'about' ? 'active' : ''}`}
        >
         <i className="fa fa-info-circle nav-icon"></i>
          {!collapsed && <span className="nav-text">About</span>}
        </div>
      </div>
      <div onClick={handleLogout} className="logout-item">
        <i className="fa fa-sign-out logout-icon"></i>
        {!collapsed && <span className="nav-text">Logout</span>}
      </div>
    </nav>
  );
}

export default NavBar;
