import React from 'react';
import './Login.css';
import googleLogo from '../assets/google-logo.svg'; // Adjust the path as necessary
import config from '../Config/config';

function Login() {
  const handleGoogleLogin = () => {
    window.location.href = config.googlelogin;
  };

  return (
    <div className="login">
      <div className="login-box">
        <h1>
          <span className="comply">Comply</span>
          <span className="bot">Bot</span>
        </h1>
        <button onClick={handleGoogleLogin} className="google-login-button">
          <span>Login with</span>
          <img src={googleLogo} alt="Google Logo" className="google-logo" />
        </button>
      </div>
    </div>
  );
}

export default Login;