import React from 'react';
import { useLocation } from 'react-router-dom';
import './Layout.css';
import logo from '../assets/setu-logo.svg';

function Layout({ children }) {
  const location = useLocation();

  return (
    <div className="layout-container">
      {(location.pathname === '/') && (
        <header className="layout-header">
          <img src={logo} alt="Setu Logo" className="layout-logo" />
        </header>
      )}
      <div className="content-container">
        {children}
      </div>
    </div>
  );
}

export default Layout;
