import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Login from './Login/Login';
import FileUpload from './FileUpload/FileUpload';
import Layout from './Layout/Layout';
import config from './Config/config';
import About from './About/About';

function App() {
  const [theme, setTheme] = useState('dark');
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [visibleSection, setVisibleSection] = useState('getAnswers'); // Control section state
  const [isCollapsed, setIsCollapsed] = useState(false); // Control collapse state

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);

    // Check if Google auth is enabled
    if (config.ENABLE_GOOGLE_AUTH) {
      fetch(config.current_user, {
        credentials: 'include',
      })
        .then(response => response.json())
        .then(user => {
          if (user) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        })
        .catch(error => {
          console.error('Error fetching authentication status:', error);
          setIsAuthenticated(false);
        });
    } else {
      setIsAuthenticated(true);
    }
  }, [theme]);

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
  };

  const showSection = (section) => {
    setVisibleSection(section); // Update the section being displayed
  };

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Layout
          theme={theme}
          onThemeChange={handleThemeChange}
          isAuthenticated={isAuthenticated}
          visibleSection={visibleSection}  // Pass the section state to Layout
          showSection={showSection}        // Pass the function to change the section
          isCollapsed={isCollapsed}        // Pass collapse state to Layout
          setIsCollapsed={setIsCollapsed}  // Function to control collapse state
        >
          <Routes>
            <Route path="/" element={<Login />} />
            {/* The upload page handles both sections */}
            <Route
              path="/upload"
              element={isAuthenticated ? <FileUpload visibleSection={visibleSection} /> : <Navigate to="/" />}
            />
            {/* The about page is a separate route */}
            <Route
              path="/about"
              element={isAuthenticated ? <About /> : <Navigate to="/" />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Layout>
      </div>
    </Router>
  );
}

export default App;
