import React, { useState, useEffect } from 'react';
import './About.css';

function About() {
    const [visibleCards, setVisibleCards] = useState([false, false, false]);

    useEffect(() => {
        // Show the cards one by one with a delay
        const timers = [0, 1, 2].map((index) =>
            setTimeout(() => {
                setVisibleCards((prevVisibleCards) => {
                    const newVisibleCards = [...prevVisibleCards];
                    newVisibleCards[index] = true;
                    return newVisibleCards;
                });
            }, index * 500) // delay of 500ms between each card
        );

        return () => timers.forEach((timer) => clearTimeout(timer)); // Cleanup timeouts on unmount
    }, []); // Empty dependency array to only run on mount

    return (
        <div className="about-container">
            <div className={`card ${visibleCards[0] ? 'visible' : ''}`}>
                <h3>What is ComplyBot?</h3>
                <p>
                    ComplyBot is a compliance automation tool designed to help Setu adhere to regulations. It leverages both pretrained models and an auto-trained model, which is actively being developed, to provide answers to audit-related questions. The tool streamlines the regulatory process, making compliance more efficient and accurate.
                </p>
            </div>
            <div className={`card ${visibleCards[1] ? 'visible' : ''}`}>
                <h3>Aim</h3>
                <p>
                    The aim of ComplyBot is to simplify and automate the compliance process, ensuring that Setu can efficiently meet regulatory requirements. By providing accurate, real-time answers to audit and compliance-related questions, ComplyBot reduces the manual effort involved in regulatory adherence, minimizes the risk of non-compliance.
                </p>
            </div>
            <div className={`card ${visibleCards[2] ? 'visible' : ''}`}>
                <h3>Get in Touch</h3>
                <p>
                    Reach out via email for any questions or assistance
                </p>
                <ul className="contact-emails">
                    <li>
                        <i className="fas fa-envelope"></i>
                        <a href="mailto:automation-qa@setu.co">automation-qa@setu.co</a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default About;
